<template>
  <iframe
    src="https://bancannabis.org/cannaverse"
    frameborder="0"
    style="width: 100%; height: 100%"
    allowfullscreen
  />
</template>

<script>
// import { BCard } from 'bootstrap-vue'

export default {
  components: {
  },
}
</script>

<style scoped>

</style>
